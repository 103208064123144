<template>
  <div class="vue-list-item">
    <a :href="itemUrl"
       v-show="show"
       @click.prevent="onClick">
      {{this.name}}
      <template v-if="!isLeaf"><span><i class="fa fa-angle-right fa-lg pull-right"></i></span></template>
    </a>
    <transition name="slide-fade">
      <AppList v-if="showSubMenu">
        <div class="vue-list-back-button">
          <button v-show="showBackButton && backButtonLabel.length > 0"
                  @click="goBack">
            <i class="fa fa-angle-left fa-lg"></i> {{backButtonLabel}}
          </button>
        </div>
        <AppListItem v-for="item in children"
                     :key="item.id"
                     :name="item.name"
                     :path="item.url"
                     :parent-id="item.parent_id"
                     :is-leaf="item.is_leaf"
                     :menu-level="nextMenuLevel"
                     @click="onClick"
                     @back="goBack"
        />
      </AppList>
    </transition>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'AppListItem',
    props: {
      name: String,
      path: String,
      isLeaf: Boolean,
      parentId: Number,
      menuLevel: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        showSubMenu: false
      }
    },
    computed: {
      ...mapGetters([
        'childrenTaxonomies',
        'selectedMenuId',
        'currentMenuLevel'
      ]),
      itemUrl() {
        return `${this.$store.getters.baseUrl}${this.path}`;
      },
      children() {
        if(this.isLeaf) return [];
        return this.childrenTaxonomies(this.$vnode.key);
      },
      show() {
        return this.selectedMenuId === this.parentId;
      },
      showBackButton() {
        return this.selectedMenuId === this.$vnode.key && this.currentMenuLevel === this.menuLevel;
      },
      backButtonLabel() {
        if (this.currentMenuLevel === 1) {
          return (this.$store.getters.currentLocale.toLowerCase() == 'en' ? 'ALL CATEGORIES' : 'TOATE CATEGORIILE')
        } else {
          let taxon = this.$store.getters.allTaxonomies.find((t) => t.id == this.parentId);
          if (taxon) {
            return taxon.name.toUpperCase();
          } else {
            return '';
          }
        }
      },
      nextMenuLevel() {
        return this.menuLevel + 1;
      }
    },
    methods: {
      onClick() {
        if(this.isLeaf) {
          window.location.href = this.itemUrl;
        } else {
          this.showSubMenu = true;
          this.$store.dispatch('setMenu', { parentId: this.$vnode.key });
          this.$store.dispatch('setMenuLevel', { menuLevel: this.currentMenuLevel + 1 });
        }
      },
      goBack() {
        this.showSubMenu = false;
        this.$store.dispatch('setMenu', { parentId: this.parentId });
        this.$store.dispatch('setMenuLevel', { menuLevel: this.currentMenuLevel - 1 });
      }
    }
  }
</script>
