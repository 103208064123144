let request = null;

export default {
  get(url, params, options = {}) {
    if (request && options.abortingXHR) {
      request.abort();
      request = null;
    }

    return new Promise((resolve, reject) => {
      request = $.ajax(
        $.extend({url: url, data: params, dataType: 'json'}, options)
      );

      request.then(resolve)
      .done(() => {
        request = null;
      })
      .fail((error) => {
        console.log(error);
        reject(error);
      });
    });
  },
  post(url, params) {
    return new Promise((resolve, reject) => {
      $.ajax(url, {
        type: 'POST',
        dataType: 'json',
        data: {
          _method: 'post',
          ...params
        },
      }).done(resolve).fail((error) => {
        console.log(error);
        reject(error);
      });
    });
  }
}