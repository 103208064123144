<template>
  <div class="vue-list">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'AppList'
  }
</script>