<template>
  <li :class="(selected ? 'active' : '') + ' nav-item'">
    <a :id="id +'-tab'"
       :href="'#' + id"
       data-toggle="tab"
       role="tab"
       class="nav-link"
       :aria-controls="id"
       :aria-selected="selected"
    >
      {{label}}
    </a>
  </li>
</template>

<script>
  export default {
    name: 'AppTab',
    props: {
      label: String,
      selected: Boolean
    },
    data() {
      return {
        id: this.label.replace(/\s+/g, '-').toLowerCase()
      }
    }
  }
</script>