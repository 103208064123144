<template>
  <div class="vue-tabs">
    <ul class="nav nav-tabs">
      <slot name="tabs"></slot>
    </ul>

    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'AppTabs',
    props: {
      value: String
    }
  }
</script>