// TODO: extract the org specific states into a common utility JS modules
// i.e.: organization, permission_denied, loading
export default {
  taxonomies: [],
  loading: false,
  base_url: window.location.protocol + '//' + window.location.hostname,
  taxonomies_url: window.location.protocol + '//' + window.location.hostname + '/sidebar.json',
  api_errors: [],
  selected_menu_id: 0,
  menu_level: 0,
  locale: '',
  currency: '',
  locale_options: [
    {label: 'Romanian', value: 'ro', flag: 'ro'},
    {label: 'English', value: 'en', flag: 'gb'}
  ],
  currency_options: ['RON', 'EUR'],
  static_pages: [],
  account_pages: []
}
