import {orderBy} from 'lodash-es';

export default {
  baseUrl: state => state.base_url,
  showLoading: state => state.loading,
  allTaxonomies: state => state.taxonomies,
  selectedMenuId: state => state.selected_menu_id,
  currentMenuLevel: state => state.menu_level,
  currentLocale: state => state.locale,
  currentCurrency: state => state.currency,
  selectLocaleOptions: state => state.locale_options,
  selectCurrencyOptions: state => state.currency_options,
  staticHeaderPages: state => state.static_pages,
  accountPages: state => state.account_pages,
  parentTaxonomies: (_state, getters) => {
    let result = [];
    if(getters.allTaxonomies) {
      orderBy(getters.allTaxonomies, 'name').forEach((taxon) => {
        if(!taxon.parent_id) {
          result.push(taxon);
        }
      });
    }
    return result;
  },
  childrenTaxonomies: state => {
    return parentId => {
      if(!parentId) return state.taxonomies;
      return orderBy(state.taxonomies, 'name').filter(taxon => taxon.parent_id === parentId);
    }
  }
}
