import AppList from '../components/AppList'
import AppListItem from '../components/AppListItem'
import AppTabs from '../components/AppTabs'
import AppTab from '../components/AppTab'
import vSelect from 'vue-select'

export default {
  install(Vue, _options) {
    Vue.component('AppList', AppList);
    Vue.component('AppListItem', AppListItem);
    Vue.component('AppTabs', AppTabs);
    Vue.component('AppTab', AppTab);
    Vue.component('v-select', vSelect)
  },
  createdHook(initialState) {
    return function() {
      this.$store.dispatch('loadInitialState', initialState);
    }
  },
  mountedHook() {
    return function() {
      this.$store.dispatch('loadData').catch(() => {
        console.log('Error loading data');
      });
    }
  }
}