<template>
  <div id="vue-sidebar">
    <AppTabs>
      <template slot="tabs">
        <AppTab
            :label="tabsLabels[0]"
            :selected="true" />
        <AppTab
            :label="tabsLabels[1]" />
      </template>

      <div class="tab-content">
        <div class="tab-pane active"
             id="magazin"
             role="tabpanel"
             aria-labelledby="magazin-tab">
          <AppList>
            <AppListItem :name="'HOME'"
                         :path="'/'"
                         :is-leaf="true"
                         :parent-id="parentId"
                         class="home-link"
            />
            <AppListItem v-for="item in parentTaxonomies"
                         :key="item.id"
                         :name="item.name"
                         :path="item.url"
                         :is-leaf="item.is_leaf"
                         :parent-id="parentId"
                         :menu-level="menuLevel + 1"
            />
          </AppList>
        </div>
        <div class="tab-pane"
             id="contul-meu"
             role="tabpanel"
             aria-labelledby="contul-meu-tab">
          <AppList id="vue-sidebar-app-list">
            <AppListItem :name="'HOME'"
                         :path="'/'"
                         :is-leaf="true"
                         :parent-id="parentId"
                         class="home-link"
            />
            <AppListItem v-for="page in staticHeaderPages"
                         :key="page.id"
                         :name="page.title"
                         :path="page.slug"
                         :is-leaf="true"
                         :parent-id="parentId"
            />
            <AppListItem v-for="page in accountPages"
                         :key="page.id"
                         :name="page.name"
                         :path="page.path"
                         :is-leaf="true"
                         :parent-id="parentId"
            />
            <v-select :options="selectLocaleOptions"
                      :clearable="false"
                      :value="currentLocale"
                      @input="onLocaleSelect"
            >
              <template slot="selected-option" slot-scope="option">
                {{defaultLanguageLabel}}
              </template>
              <template slot="option" slot-scope="option">
                <span :class="`flag-icon flag-icon-${option.flag}`"></span>
                {{option.label}} <span v-if="currentLocale == option.value"><i class="fa fa-check text-success pull-right"></i></span>
              </template>
            </v-select>
            <v-select :options="selectCurrencyOptions"
                      :clearable="false"
                      :value="currentCurrency"
                      @input="onCurrencySelect"
            >
              <template slot="selected-option" slot-scope="option">
                {{defaultCurrencyLabel}}
              </template>
              <template slot="option" slot-scope="option">
                {{option.label}} <span v-if="currentCurrency == option.label"><i class="fa fa-check text-success pull-right"></i></span>
              </template>
            </v-select>
          </AppList>
        </div>
      </div>
    </AppTabs>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';

  export default {
    name: 'AppSidebar',
    data: function () {
      return {
        message: "Hello Vue!",
        parentId: 0,
        menuLevel: 0
      }
    },
    watch: {
      showLoading(newShowLoading) {
        $(this.$el).loadingCurtain(newShowLoading ? 'show' : 'hide');
      }
    },
    computed: {
      ...mapGetters([
        'parentTaxonomies',
        'staticHeaderPages',
        'accountPages',
        'currentLocale',
        'currentCurrency',
        'selectLocaleOptions',
        'selectCurrencyOptions',
        'showLoading'
      ]),
      ...mapState({
        locale: 'locale'
      }),
      tabsLabels() {
        if(this.$store.getters.currentLocale == 'en') {
          return ['Store', 'My account']
        } else {
          return ['Magazin', 'Contul meu']
        }
      },
      defaultLanguageLabel() {
        return this.currentLocale == 'ro' ? 'Limba' : 'Language';
      },
      defaultCurrencyLabel() {
        return this.currentLocale == 'ro' ? 'Moneda' : 'Currency';
      }
    },
    methods: {
      onLocaleSelect(locale) {
        if (this.currentLocale !== locale.value && locale.value !== undefined) {
          this.$store.dispatch('changeLocale', { locale: locale.value });
        }
      },
      onCurrencySelect(currency) {
        if (this.currentCurrency !== currency && currency !== undefined) {
          this.$store.dispatch('changeCurrency', { currency: currency });
        }
      }
    }
  }
</script>
