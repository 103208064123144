import Vue from 'vue'
import * as types from './types'

// TODO: the LOAD_STATE and SET_LOADING mutations can be moved into a common utility JS module
export default {
  [types.LOAD_STATE](state, data) {
    if (data === undefined) return;

    for (const k in data) {
      // [TODO] - temporary until https://github.com/vuejs/vuex/pull/1043
      if (k !== 'vueMeta') {
        Vue.set(state, k, data[k]);
      }
    }
  },
  [types.SET_LOADING](state, pending) {
    if (pending === undefined) return;
    state.loading = pending;
  },
  [types.API_ERRORS](state, errors) {
    state.api_errors = errors;
  },
  [types.SET_MENU_ID](state, data) {
    if (data.parentId === undefined) {
      state.selected_menu_id = 0;
    } else {
      state.selected_menu_id = data.parentId;
    }
  },
  [types.SET_MENU_LEVEL](state, data) {
    state.menu_level = data.menuLevel;
  }
}
