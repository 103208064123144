/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue'

// Common Vue pieces
import BwApp from '../plugins/bw_app'

// Pack-level pieces
import AppSidebar from '../AppSidebar.vue'
import store from '../stores/AppStore'

$(() => {
  $('div[data-vue="app-sidebar"]').each(function () {
    Vue.use(BwApp);

    const el = this;
    const initialState = $(el).data('initialState');
    const app = new Vue({
      el,
      store,
      render: h => h(AppSidebar),
      created: BwApp.createdHook(initialState),
      mounted: BwApp.mountedHook()
    });
  });
});
